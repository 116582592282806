var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "animated fadeIn" },
    [
      _c(
        "b-row",
        [
          _c(
            "b-col",
            { attrs: { xs: "12", lg: "12" } },
            [
              _c(
                "b-card",
                [
                  _c(
                    "div",
                    [
                      _c("h4", { staticClass: "d-inline" }, [
                        _c("i", { staticClass: "fa fa-building" }),
                        _vm._v(" All Companies")
                      ]),
                      _c(
                        "router-link",
                        {
                          staticClass: "float-right btn btn-success",
                          attrs: { to: { name: "Admin Add Company" } }
                        },
                        [
                          _vm._v("Add Company "),
                          _c("i", { staticClass: "fa fa-plus" })
                        ]
                      )
                    ],
                    1
                  ),
                  _c("hr", { staticClass: "my-4" }),
                  _c("v-client-table", {
                    attrs: {
                      columns: _vm.columns,
                      data: _vm.companyData,
                      options: _vm.options,
                      theme: _vm.theme,
                      id: "dataTable"
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "avatar",
                        fn: function(props) {
                          return _c("span", {}, [
                            !props.row.avatar
                              ? _c("span", [_vm._v("No Avatar")])
                              : _vm._e(),
                            _c("img", {
                              staticClass: "vendorlogo",
                              attrs: { src: props.row.avatar }
                            })
                          ])
                        }
                      },
                      {
                        key: "name",
                        fn: function(props) {
                          return _c(
                            "span",
                            {},
                            [
                              _c(
                                "router-link",
                                {
                                  attrs: {
                                    to: {
                                      name: "Company",
                                      params: {
                                        id: props.row.companyid,
                                        company: props.row
                                      }
                                    }
                                  },
                                  nativeOn: {
                                    click: function($event) {
                                      return _vm.setCompany(props.row)
                                    }
                                  }
                                },
                                [
                                  _vm._v(
                                    _vm._s(props.row.name) + "\n            "
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        }
                      },
                      {
                        key: "portal_address",
                        fn: function(props) {
                          return _c("span", {}, [
                            _c(
                              "a",
                              { attrs: { href: props.row.portal_address } },
                              [_vm._v(_vm._s(props.row.portal_address))]
                            )
                          ])
                        }
                      },
                      {
                        key: "creation_date",
                        fn: function(props) {
                          return _c("span", {}, [
                            _vm._v(
                              _vm._s(
                                _vm._f("moment")(
                                  props.row.creation_date,
                                  "M/D/YYYY"
                                )
                              )
                            )
                          ])
                        }
                      },
                      {
                        key: "actions",
                        fn: function(props) {
                          return _c(
                            "div",
                            {},
                            [
                              _c(
                                "router-link",
                                {
                                  staticClass: "btn btn-sm btn-primary mr-3",
                                  attrs: {
                                    to: {
                                      name: "Company",
                                      params: {
                                        id: props.row.companyid,
                                        company: props.row
                                      }
                                    }
                                  },
                                  nativeOn: {
                                    click: function($event) {
                                      return _vm.setCompany(props.row)
                                    }
                                  }
                                },
                                [
                                  _vm._v("Managers "),
                                  _c("i", {
                                    staticClass: "fa fa-chevron-right"
                                  })
                                ]
                              ),
                              _c(
                                "b-btn",
                                {
                                  directives: [
                                    {
                                      name: "b-modal",
                                      rawName: "v-b-modal.editCompany",
                                      modifiers: { editCompany: true }
                                    }
                                  ],
                                  attrs: { variant: "primary btn-sm" },
                                  on: {
                                    click: function($event) {
                                      return _vm.sendInfo(props.row.companyid)
                                    },
                                    ok: function($event) {}
                                  }
                                },
                                [
                                  _vm._v("Edit\n              "),
                                  _c("i", { staticClass: "fa fa-edit" })
                                ]
                              ),
                              _c("input", {
                                staticClass: "ml-3 primary btn-sm",
                                staticStyle: { width: "190px" },
                                attrs: {
                                  accept: "image/x-png,image/jpeg",
                                  type: "file"
                                },
                                on: {
                                  change: function($event) {
                                    return _vm.onFileChanged($event)
                                  }
                                }
                              }),
                              _c(
                                "b-btn",
                                {
                                  attrs: { variant: "primary btn-sm" },
                                  on: {
                                    click: function($event) {
                                      return _vm.uploadAvatar(
                                        props.row.companyid
                                      )
                                    }
                                  }
                                },
                                [_vm._v("Upload Avatar")]
                              )
                            ],
                            1
                          )
                        }
                      }
                    ])
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "b-modal",
        {
          ref: "editModal",
          attrs: {
            id: "editCompany",
            title: "Edit Company",
            "title-tag": "h2",
            centered: "",
            size: "lg",
            "hide-footer": ""
          }
        },
        [
          _c(
            "form",
            {
              staticClass: "pb-2",
              on: {
                submit: function($event) {
                  $event.preventDefault()
                  return _vm.validateBeforeSubmit($event)
                }
              }
            },
            [
              _c("label", [_vm._v("Company Name")]),
              _c(
                "b-input-group",
                { staticClass: "mb-2" },
                [
                  _c(
                    "b-input-group-prepend",
                    [
                      _c("b-input-group-text", [
                        _c("i", { staticClass: "fa fa-user" })
                      ])
                    ],
                    1
                  ),
                  _c("b-form-input", {
                    directives: [
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: "required",
                        expression: "'required'"
                      }
                    ],
                    staticClass: "form-control",
                    attrs: {
                      type: "text",
                      name: "name",
                      placeholder: "Company Name"
                    },
                    model: {
                      value: _vm.name,
                      callback: function($$v) {
                        _vm.name = $$v
                      },
                      expression: "name"
                    }
                  })
                ],
                1
              ),
              _c("i", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.errors.has("name"),
                    expression: "errors.has('name')"
                  }
                ],
                staticClass: "fa fa-exclamation-triangle"
              }),
              _c(
                "span",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.errors.has("name"),
                      expression: "errors.has('name')"
                    }
                  ],
                  staticClass: "help is-danger"
                },
                [_vm._v("The company name is required.")]
              ),
              _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    { staticClass: "col-12 col-lg-6" },
                    [
                      _c("label", [_vm._v("GST Number")]),
                      _c(
                        "b-input-group",
                        { staticClass: "mb-2" },
                        [
                          _c(
                            "b-input-group-prepend",
                            [
                              _c("b-input-group-text", [
                                _c("i", { staticClass: "fa fa-user" })
                              ])
                            ],
                            1
                          ),
                          _c("b-form-input", {
                            directives: [
                              {
                                name: "validate",
                                rawName: "v-validate",
                                value: "required",
                                expression: "'required'"
                              }
                            ],
                            staticClass: "form-control",
                            attrs: {
                              type: "text",
                              name: "gst",
                              placeholder: "GST Number"
                            },
                            model: {
                              value: _vm.gst,
                              callback: function($$v) {
                                _vm.gst = $$v
                              },
                              expression: "gst"
                            }
                          })
                        ],
                        1
                      ),
                      _c("i", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.errors.has("gst"),
                            expression: "errors.has('gst')"
                          }
                        ],
                        staticClass: "fa fa-exclamation-triangle"
                      }),
                      _c(
                        "span",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.errors.has("gst"),
                              expression: "errors.has('gst')"
                            }
                          ],
                          staticClass: "help is-danger"
                        },
                        [_vm._v("The GST number is required.")]
                      )
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { staticClass: "col-12 col-lg-6" },
                    [
                      _c("label", [_vm._v("Registration Company Number")]),
                      _c(
                        "b-input-group",
                        { staticClass: "mb-2" },
                        [
                          _c(
                            "b-input-group-prepend",
                            [
                              _c("b-input-group-text", [
                                _c("i", { staticClass: "fa fa-user" })
                              ])
                            ],
                            1
                          ),
                          _c("b-form-input", {
                            directives: [
                              {
                                name: "validate",
                                rawName: "v-validate",
                                value: "required",
                                expression: "'required'"
                              }
                            ],
                            staticClass: "form-control",
                            attrs: {
                              type: "text",
                              name: "rcn",
                              placeholder: "Registration Company Number"
                            },
                            model: {
                              value: _vm.rcn,
                              callback: function($$v) {
                                _vm.rcn = $$v
                              },
                              expression: "rcn"
                            }
                          })
                        ],
                        1
                      ),
                      _c("i", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.errors.has("rcn"),
                            expression: "errors.has('rcn')"
                          }
                        ],
                        staticClass: "fa fa-exclamation-triangle"
                      }),
                      _c(
                        "span",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.errors.has("rcn"),
                              expression: "errors.has('rcn')"
                            }
                          ],
                          staticClass: "help is-danger"
                        },
                        [_vm._v("The company number is required.")]
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c("hr"),
              _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    { staticClass: "col-12 col-lg-6" },
                    [
                      _c("label", [_vm._v("Address Line 1")]),
                      _c(
                        "b-input-group",
                        { staticClass: "mb-2" },
                        [
                          _c(
                            "b-input-group-prepend",
                            [
                              _c("b-input-group-text", [
                                _c("i", { staticClass: "fa fa-building" })
                              ])
                            ],
                            1
                          ),
                          _c("b-form-input", {
                            directives: [
                              {
                                name: "validate",
                                rawName: "v-validate",
                                value: "required",
                                expression: "'required'"
                              }
                            ],
                            staticClass: "form-control",
                            attrs: {
                              type: "text",
                              name: "addressline2",
                              placeholder: "Address"
                            },
                            model: {
                              value: _vm.addressline1,
                              callback: function($$v) {
                                _vm.addressline1 = $$v
                              },
                              expression: "addressline1"
                            }
                          })
                        ],
                        1
                      ),
                      _c("i", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.errors.has("address"),
                            expression: "errors.has('address')"
                          }
                        ],
                        staticClass: "fa fa-exclamation-triangle"
                      }),
                      _c(
                        "span",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.errors.has("address"),
                              expression: "errors.has('address')"
                            }
                          ],
                          staticClass: "help is-danger"
                        },
                        [_vm._v("The address is required.")]
                      )
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { staticClass: "col-12 col-lg-6" },
                    [
                      _c("label", [_vm._v("Address Line 2")]),
                      _c(
                        "b-input-group",
                        { staticClass: "mb-2" },
                        [
                          _c(
                            "b-input-group-prepend",
                            [
                              _c("b-input-group-text", [
                                _c("i", { staticClass: "fa fa-building" })
                              ])
                            ],
                            1
                          ),
                          _c("b-form-input", {
                            staticClass: "form-control",
                            attrs: {
                              type: "text",
                              name: "addressline2",
                              placeholder: "Address"
                            },
                            model: {
                              value: _vm.addressline2,
                              callback: function($$v) {
                                _vm.addressline2 = $$v
                              },
                              expression: "addressline2"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    { staticClass: "col-12 col-lg-6" },
                    [
                      _c("label", [_vm._v("City")]),
                      _c(
                        "b-input-group",
                        { staticClass: "mb-2" },
                        [
                          _c(
                            "b-input-group-prepend",
                            [
                              _c("b-input-group-text", [
                                _c("i", { staticClass: "fa fa-building" })
                              ])
                            ],
                            1
                          ),
                          _c("b-form-input", {
                            directives: [
                              {
                                name: "validate",
                                rawName: "v-validate",
                                value: "required",
                                expression: "'required'"
                              }
                            ],
                            staticClass: "form-control",
                            attrs: {
                              type: "text",
                              name: "city",
                              placeholder: "City"
                            },
                            model: {
                              value: _vm.city,
                              callback: function($$v) {
                                _vm.city = $$v
                              },
                              expression: "city"
                            }
                          })
                        ],
                        1
                      ),
                      _c("i", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.errors.has("city"),
                            expression: "errors.has('city')"
                          }
                        ],
                        staticClass: "fa fa-exclamation-triangle"
                      }),
                      _c(
                        "span",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.errors.has("city"),
                              expression: "errors.has('city')"
                            }
                          ],
                          staticClass: "help is-danger"
                        },
                        [_vm._v("The city is required.")]
                      )
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { staticClass: "col-12 col-lg-6" },
                    [
                      _c("label", [_vm._v("Province")]),
                      _c(
                        "b-input-group",
                        { staticClass: "mb-2" },
                        [
                          _c(
                            "b-input-group-prepend",
                            [
                              _c("b-input-group-text", [
                                _c("i", { staticClass: "fa fa-building" })
                              ])
                            ],
                            1
                          ),
                          _c("b-form-select", {
                            directives: [
                              {
                                name: "validate",
                                rawName: "v-validate",
                                value: "required",
                                expression: "'required'"
                              }
                            ],
                            staticClass: "form-control",
                            attrs: {
                              type: "select",
                              options: _vm.provinceOptions,
                              name: "province",
                              placeholder: "Province"
                            },
                            model: {
                              value: _vm.province,
                              callback: function($$v) {
                                _vm.province = $$v
                              },
                              expression: "province"
                            }
                          })
                        ],
                        1
                      ),
                      _c("i", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.errors.has("province"),
                            expression: "errors.has('province')"
                          }
                        ],
                        staticClass: "fa fa-exclamation-triangle"
                      }),
                      _c(
                        "span",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.errors.has("province"),
                              expression: "errors.has('province')"
                            }
                          ],
                          staticClass: "help is-danger"
                        },
                        [_vm._v("The province is required.")]
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    { staticClass: "col-12 col-lg-6" },
                    [
                      _c("label", [_vm._v("Postal Code")]),
                      _c(
                        "b-input-group",
                        { staticClass: "mb-2" },
                        [
                          _c(
                            "b-input-group-prepend",
                            [
                              _c("b-input-group-text", [
                                _c("i", { staticClass: "fa fa-building" })
                              ])
                            ],
                            1
                          ),
                          _c("b-form-input", {
                            directives: [
                              {
                                name: "validate",
                                rawName: "v-validate",
                                value: "required",
                                expression: "'required'"
                              }
                            ],
                            staticClass: "form-control",
                            attrs: {
                              type: "text",
                              name: "postalcode",
                              placeholder: "Postal Code"
                            },
                            model: {
                              value: _vm.postalcode,
                              callback: function($$v) {
                                _vm.postalcode = $$v
                              },
                              expression: "postalcode"
                            }
                          })
                        ],
                        1
                      ),
                      _c("i", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.errors.has("postalcode"),
                            expression: "errors.has('postalcode')"
                          }
                        ],
                        staticClass: "fa fa-exclamation-triangle"
                      }),
                      _c(
                        "span",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.errors.has("postalcode"),
                              expression: "errors.has('postalcode')"
                            }
                          ],
                          staticClass: "help is-danger"
                        },
                        [_vm._v("The postal code is required.")]
                      )
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { staticClass: "col-12 col-lg-6" },
                    [
                      _c("label", [_vm._v("Website")]),
                      _c(
                        "b-input-group",
                        { staticClass: "mb-2" },
                        [
                          _c(
                            "b-input-group-prepend",
                            [
                              _c("b-input-group-text", [
                                _c("i", { staticClass: "fa fa-building" })
                              ])
                            ],
                            1
                          ),
                          _c("b-form-input", {
                            directives: [
                              {
                                name: "validate",
                                rawName: "v-validate",
                                value: "url",
                                expression: "'url'"
                              }
                            ],
                            staticClass: "form-control",
                            attrs: {
                              type: "text",
                              name: "portal_address",
                              placeholder: "Website Address"
                            },
                            model: {
                              value: _vm.portal_address,
                              callback: function($$v) {
                                _vm.portal_address = $$v
                              },
                              expression: "portal_address"
                            }
                          })
                        ],
                        1
                      ),
                      _c("i", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.errors.has("portal_address"),
                            expression: "errors.has('portal_address')"
                          }
                        ],
                        staticClass: "fa fa-exclamation-triangle"
                      }),
                      _c(
                        "span",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.errors.has("portal_address"),
                              expression: "errors.has('portal_address')"
                            }
                          ],
                          staticClass: "help is-danger"
                        },
                        [_vm._v("The url is not valid.")]
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "b-row",
                [
                  _c("b-col", { staticClass: "col-12 col-lg-6" }, [
                    _c("label", [_vm._v("Price Agreement")]),
                    _vm._v("\n          Price Per Day\n          "),
                    _c("div", { staticClass: "form-group" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.pricePerDay,
                            expression: "pricePerDay"
                          }
                        ],
                        staticClass: "form-control",
                        attrs: {
                          type: "number",
                          step: "any",
                          min: "0",
                          max: "100"
                        },
                        domProps: { value: _vm.pricePerDay },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.pricePerDay = $event.target.value
                          }
                        }
                      })
                    ]),
                    _vm._v("\n          Price Per AFE Count\n          "),
                    _c("div", { staticClass: "form-group" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.pricePerAfe,
                            expression: "pricePerAfe"
                          }
                        ],
                        staticClass: "form-control",
                        attrs: {
                          type: "number",
                          step: "any",
                          min: "0",
                          max: "100"
                        },
                        domProps: { value: _vm.pricePerAfe },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.pricePerAfe = $event.target.value
                          }
                        }
                      })
                    ]),
                    _vm._v(
                      "\n          Percent Of Total Invoices Cost\n          "
                    ),
                    _c("div", { staticClass: "form-group" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.percentTotal,
                            expression: "percentTotal"
                          }
                        ],
                        staticClass: "form-control",
                        attrs: {
                          type: "number",
                          step: "any",
                          min: "0",
                          max: "100"
                        },
                        domProps: { value: _vm.percentTotal },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.percentTotal = $event.target.value
                          }
                        }
                      })
                    ])
                  ])
                ],
                1
              ),
              _c(
                "b-button",
                {
                  staticClass: "mt-4",
                  attrs: { variant: "success", type: "submit", block: "" }
                },
                [_vm._v("Edit Company")]
              )
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }